<template>
  <div class="ratings d-none d-md-flex align-items-center">
    <div class="price">{{ reviewData.name }}:</div>
    <div class="stars">
      <ul class="ratings-star">
        <li
          v-for="(isFill, idx) of rateStars"
          :key="idx"
          :class="isFill ? 'text-yellow' : 'text-gray'"
        >
          <i class="lnr lnr-star" @click="clickStar(idx)"></i>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReviewRating",
  props: {
    reviewData: {
      type: Object,
    },
  },
  computed: {
    rateStars() {
      return new Array(5).fill(false).map((_, i) => i < this.stars);
    },
  },
  data() {
    return { stars: 0 };
  },
  methods: {
    clickStar(idx) {
      this.stars = parseInt(idx + 1);
      const found = this.reviewData.values.find((v) => {
        if (parseInt(v.value) == this.stars) {
          return true;
        }
      });
      if (found != null) {
        this.$emit("input", found.value_id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ratings {
  padding: 5px 0;
  line-height: 1;
  margin-bottom: 7px;
}

.text-yellow {
  color: green;
}

.text-gray {
  color: #cdcdcd;
}

.ratings-star {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0 -4px;

  li {
    margin: 0 2px;
  }
}
</style>

<template>
  <div class="product-gallery">
    <div
      class="selected-image-holder d-none d-md-block"
      @click.prevent="showMultiple"
    >
      <b-link href="#" @click.stop="toggleIsInWishList" v-if="isLoggedIn">
        <i class="lnr lnr-heart"></i>
        <div class="heart-icon" v-if="isInWishList">
          <img src="@/esf_lommel_dt1_europe/assets/heart.png" alt="heart" />
        </div>
      </b-link>
      <div
        class="selected-image"
        :style="{ backgroundImage: `url(${selectedImage.url})` }"
      ></div>
    </div>
    <ClientOnly>
      <Lightbox
        :visible="visible"
        :imgs="selectedImage.url"
        :index="index"
        @hide="handleHide"
      ></Lightbox>
      <Carousel
        class="carousel"
        :autoplay="false"
        :loop="true"
        :navigationEnabled="true"
        :perPage="noOfSlidePerPage"
        :scrollPerPage="false"
        :paginationEnabled="false"
        navigationPrevLabel="<i class='lnr lnr-chevron-left'></i>"
        navigationNextLabel="<i class='lnr lnr-chevron-right'></i>"
      >
        <b-link
          href="#"
          @click="toggleIsInWishList"
          class="mobile-add-to-wishlist"
        >
          <i class="lnr lnr-heart"></i>
          <div class="heart-icon" v-if="isInWishList">
            <img src="@/esf_lommel_dt1_europe/assets/heart.png" alt="heart" />
          </div>
        </b-link>
        <Slide v-for="(image, i) of images" :key="`${i}`" :id="`slide ${i}`">
          <b-link href="#" class="slide-img-holder" @click="clickSlideGallery">
            <div
              class="slide-img"
              :style="{ backgroundImage: `url(${image.url})` }"
            ></div>
          </b-link>
        </Slide>
      </Carousel>
    </ClientOnly>
  </div>
</template>

<script>
//import { Carousel, Slide } from "vue-carousel";
import { isServer } from "@storefront/core/helpers";
import ClientOnly from "vue-client-only";

//import Lightbox from "vue-easy-lightbox";
import { Logger } from "@storefront/core/lib/logger";

export default {
  name: "ProductGallery",
  props: ["images", "isInWishList"],
  components: {
    ClientOnly,
    Carousel: () =>
      !isServer ? import("vue-carousel").then((m) => m.Carousel) : null,
    Slide: () =>
      !isServer ? import("vue-carousel").then((m) => m.Slide) : null,
    Lightbox: () => (!isServer ? import("vue-easy-lightbox") : null),
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
  data() {
    return {
      selectedImage: this.images[0],
      noOfSlidePerPage: 1,
      imgs: "",
      visible: false,
      index: 0,
      slideNumber: 0,
    };
  },
  watch: {
    images: function (oldVal, newVal) {
      Logger.debug("watch", "newval[0]", newVal[0])();
      Logger.debug("watch", "this.selectedImage", this.selectedImage)();

      this.selectedImage = newVal[0];

      Logger.debug("watch", "this.selectedImage", this.selectedImage)();

      Logger.debug("watch", "oldVal", oldVal)();
      Logger.debug("watch", "newVal", newVal)();
    },
  },
  mounted() {
    window.addEventListener("resize", this.setNoOfSlidePerPage);
    window.addEventListener("load", this.setNoOfSlidePerPage);
    this.setNoOfSlidePerPage();
  },
  destroyed() {
    window.removeEventListener("resize", this.setNoOfSlidePerPage);
    window.removeEventListener("load", this.setNoOfSlidePerPage);
  },
  methods: {
    clickSlideGallery(e) {
      this.changeSelectedImage(e);
      if (window.innerWidth < 768) {
        this.showMultiple();
      }
    },
    changeSelectedImage(e) {
      const slideNumber = e.target.parentElement.id.replace(/^\D+/g, "");
      this.slideNumber = +slideNumber;
      this.selectedImage = this.images[slideNumber];
    },
    showMultiple() {
      this.imgs = this.images;
      this.index = this.slideNumber;
      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    setNoOfSlidePerPage() {
      this.noOfSlidePerPage = window.innerWidth < 768 ? 1 : 3;
    },
    toggleIsInWishList() {
      this.$emit("changeIsInWishList");
    },
  },
};
</script>

<style lang="scss">
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
.product-gallery {
  @include media("screen", ">=tablet") {
    margin-bottom: 55px;
  }

  .selected-image-holder {
    width: 100%;
    height: 555px;
    border: 2px solid #d7d7d7;
    margin-bottom: 19px;
    padding: 60px 10px;
    position: relative;

    @include media("screen", ">=desktop") {
      padding: 45px;
    }

    i {
      position: absolute;
      top: 12px;
      right: 13px;
      font-size: 22px;
    }

    .heart-icon {
      width: 23px;
      position: absolute;
      top: 9px;
      right: 13px;
      z-index: 888;

      img {
        width: 100%;
      }
    }

    .selected-image {
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }
  }

  .slide-img-holder {
    display: block;
    border: 2px solid #d7d7d7;
    width: 100%;
    height: 395px;
    padding: 35px;

    @include media("screen", ">=tablet") {
      padding: 10px;
      width: 85px;
      height: 85px;
      margin: 0 7px;
    }

    .slide-img {
      width: 100%;
      height: 100%;
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      max-width: 371px;
      margin: 0 auto;
      pointer-events: none;

      @include media("screen", ">=tablet") {
        max-width: none;
        margin: 0;
      }
    }
  }

  .VueCarousel-inner {
    @include media("screen", ">=tablet") {
      margin: 0 -7px;
    }
  }

  .VueCarousel-navigation-button {
    outline: none !important;
  }

  .VueCarousel-navigation-prev {
    left: 34px !important;

    @include media("screen", ">=tablet") {
      left: -3px !important;
    }
  }

  .VueCarousel-navigation-next {
    right: 34px !important;

    @include media("screen", ">=tablet") {
      right: -3px !important;
    }
  }

  .carousel {
    @include media("screen", ">=tablet") {
      width: 283px;
      margin: 0 auto;
    }
  }

  .mobile-add-to-wishlist {
    position: absolute;
    top: 10px;
    right: 16px;
    font-size: 20px;

    @include media("screen", ">=tablet") {
      display: none;
    }

    .heart-icon {
      position: absolute;
      top: -2px;
      left: -1px;
      z-index: 888;
    }
  }

  .vel-toolbar .toobar-btn.toolbar-btn__resize {
    display: none;
  }
}
</style>

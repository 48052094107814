var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-card--holder"},[(_vm.isLoggedIn)?_c('b-link',{staticClass:"add-to-wishlist",on:{"click":_vm.toggleWishItem}},[(_vm.$store.getters['user/isProductInWishlist'](_vm.product.sku))?_c('div',{staticClass:"heart"},[_c('img',{attrs:{"src":require("@/esf_lommel_dt1_europe/assets/heart.png"),"alt":"heart"}})]):_c('i',{staticClass:"lnr lnr-heart"})]):_vm._e(),_c('b-link',{staticClass:"product-card",attrs:{"to":_vm.$link(("/" + (_vm.product.url_key)))}},[(
        _vm.product.thumbnail.medium != null &&
        !_vm.product.thumbnail.medium.match(/\/.jpg/gi)
      )?_c('div',[_c('div',{staticClass:"product-card--img-top",style:("background-image: url('" + _vm.product.thumbnail.medium + "');")},[_c('div',{staticClass:"actionbuttons"},[(_vm.onSale == true)?_c('span',{staticClass:"sale-txt"},[_vm._v("sale")]):_vm._e(),(_vm.onNew == true)?_c('span',{staticClass:"sale-txt"},[_vm._v("new")]):_vm._e()])])]):_vm._e(),(
        _vm.product.thumbnail.medium == null ||
        _vm.product.thumbnail.medium.match(/\/.jpg/gi)
      )?_c('div',[_c('div',{staticClass:"product-card--img-top",style:("background-image: url('" + _vm.product.thumbnail.url + "');")},[_c('div',{staticClass:"actionbuttons"},[(_vm.onSale == true)?_c('span',{staticClass:"sale-txt"},[_vm._v("sale")]):_vm._e(),(_vm.onNew == true)?_c('span',{staticClass:"sale-txt"},[_vm._v("new")]):_vm._e()])])]):_vm._e(),_c('div',{staticClass:"product-card--product-name"},[_vm._v(_vm._s(_vm.product.name))]),_c('div',{staticClass:"product-card--sku"},[_vm._v(_vm._s(_vm.$t("sku"))+": "+_vm._s(_vm.product.sku))]),_vm._l((_vm.product.configurable_options),function(attr){return _c('ul',{key:attr.id,staticClass:"available-sizes-list d-none d-md-flex flex-wrap justify-content-center list-unstyled p-0"},_vm._l((attr.values),function(opt){return _c('li',{key:opt.value_index},[_vm._v(" "+_vm._s(opt.label)+" ")])}),0)}),_c('Rating',{attrs:{"stars":_vm.product.rating_summary,"noOfRatings":_vm.product.review_count}}),(_vm.isLoggedIn == true)?_c('div',[(
          _vm.product.price_range.minimum_price.final_price.value ==
          _vm.product.price_range.maximum_price.final_price.value
        )?_c('span',{staticClass:"d-block product-card--price"},[_vm._v("€"+_vm._s(_vm.product.price_range.minimum_price.final_price.value.toFixed(2)))]):_vm._e(),(
          _vm.product.price_range.minimum_price.final_price.value !=
          _vm.product.price_range.maximum_price.final_price.value
        )?_c('span',{staticClass:"d-block product-card--price"},[_vm._v("€"+_vm._s(_vm.product.price_range.minimum_price.final_price.value.toFixed(2))+" - €"+_vm._s(_vm.product.price_range.maximum_price.final_price.value.toFixed(2)))]):_vm._e(),(_vm.product.sale == 1)?_c('span',{staticClass:"d-block product-card--sale-comment"},[_vm._v(_vm._s(_vm.$t("discount"))+" "+_vm._s(_vm.product.price_range.minimum_price.discount.percent_off.toFixed(2))+"%")]):_vm._e()]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <b-modal
      id="modal-1"
      okVariant="success"
      :okTitle="$t('continue_buying')"
      :cancelTitle="$t('continue_shopping')"
      :title="$t('items_added_to_cart', { numberOf: quantity })"
      @ok="clickOk"
    >
      <img :src="product.thumbnail.url" />
      <p class="my-4">{{ product.name }}</p>
      <p class="my-price">
        &euro;{{ priceRange.maximum_price.final_price.value.toFixed(2) }}
      </p>
    </b-modal>
    <div class="d-flex justify-content-between">
      <div v-if="isLoggedIn == true">
        <div
          v-if="
            priceRange.minimum_price.final_price.value !=
            priceRange.maximum_price.final_price.value
          "
          class="top-wrap"
        >
          <div class="d-flex align-items-end price-selection">
            <span class="currentPrice"
              >{{ priceRange.minimum_price.final_price.value.toFixed(2) }} -
              &euro;{{
                priceRange.maximum_price.final_price.value.toFixed(2)
              }}</span
            >
            <span
              class="discount-comment"
              v-if="priceRange.minimum_price.discount.percent_off > 0"
              >{{ $t("discount") }} &euro;{{
                priceRange.minimum_price.discount.percent_off
              }}%</span
            >
          </div>
        </div>
        <div
          v-if="
            priceRange.minimum_price.final_price.value ==
            priceRange.maximum_price.final_price.value
          "
          class="top-wrap"
        >
          <span
            class="normal-price d-block"
            v-if="priceRange.minimum_price.discount.percent_off > 0"
            >{{ $t("advice_price") }}: &euro;{{
              priceRange.minimum_price.regular_price.value.toFixed(2)
            }}
          </span>
          <div class="d-flex align-items-end price-selection">
            <span class="currentPrice"
              >&euro;{{
                priceRange.minimum_price.final_price.value.toFixed(2)
              }}</span
            >
            <span
              class="discount-comment"
              v-if="priceRange.minimum_price.discount.percent_off > 0"
              >{{ $t("discount") }}
              {{ priceRange.minimum_price.discount.percent_off }}%</span
            >
          </div>
        </div>
      </div>
      <div v-if="product.brand != null" class="product-logo">
        <b-link :href="`/` + product.brand.urlKey">
          <img :src="product.brand.logo" :alt="product.brand.title" />
        </b-link>
      </div>
    </div>
    <div v-for="parameter of options" :key="parameter.id">
      <b-modal
        v-if="parameter.attribute_code == 'fkv_maten'"
        id="modal-scrollable"
        scrollable
        :title="$t('sizechart')"
      >
        <div class="text">
          <p>
            {{ $t("sizechart_warning") }}
          </p>
        </div>
        <b-table
          class="size-table"
          responsive
          :items="tableSize"
          :fields="fields"
        ></b-table>
      </b-modal>
      <div class="size-selection">
        <div class="selection-category d-flex justify-content-between">
          <span class="select-size">{{ parameter.label }}</span>
          <b-link
            v-if="parameter.attribute_code == 'fkv_maten'"
            v-b-modal.modal-scrollable
            href="#"
            class="size-info ml-10"
            >{{ $t("view_sizechart") }}</b-link
          >
        </div>
        <div class="select-wrapper">
          <el-select
            v-on:change="processOption(parameter.index, priceSize)"
            v-model="priceSize"
            placeholder="Selecteer"
          >
            <template slot="prefix">{{ priceSize }}</template>
            <el-option
              v-for="opt of parameter.values"
              :key="opt.value_index"
              :label="opt.label"
              :value="opt.value_index"
            >
              <span>{{ opt.label }}</span>
              <span>&euro; {{ opt.price }}</span>
            </el-option>
          </el-select>
        </div>
      </div>
      <div
        v-if="parameter.attribute_code != 'fkv_maten'"
        class="size-selection-radio"
      >
        <ul class="size-selection--available-sizes">
          <li v-for="opt of parameter.values" :key="opt.value_index">
            <label class="custom-radio-input">
              <input
                type="radio"
                :id="`${parameter.attribute_code}-${opt.value_index}`"
                :name="`${parameter.attribute_code}-select`"
                :value="opt.value_index"
                v-on:click="processOption(parameter.index, opt.value_index)"
              />
              <span class="custom-radio-input--btn font-weight-normal">{{
                opt.label
              }}</span>
            </label>
          </li>
        </ul>
      </div>
    </div>
    <!--
    <div class="multi-params" v-if="parameters.multiParams">
      <div class="heading text-right">
        <b-link>Bekijk maattabel</b-link>
      </div>
      <ul>
        <li
          v-for="(item, index) of parameters.multiParams"
          :key="item.id + index"
          class="card-row d-flex align-items-center"
        >
          <b-card no-body class="overflow-hidden">
            <b-row no-gutters>
              <b-col sm="4" md="12" lg="4" class="d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <div class="card-body-img">
                    <b-card-img
                      :src="item.img"
                      alt="Image"
                      class="rounded-0"
                    ></b-card-img>
                  </div>
                  <b-card-body>
                    <b-card-text>{{ item.name }}</b-card-text>
                  </b-card-body>
                </div>
              </b-col>
              <b-col
                sm="8"
                md="12"
                lg="8"
                class="d-flex justify-content-center flex-column card-params"
              >
                <div class="size-selection-radio d-flex align-items-center">
                  <span class="selection-category">Kies je maat</span>
                  <ul class="size-selection--available-sizes">
                    <li
                      v-for="(size, i) of item.sizes"
                      :key="size.name + i + index"
                    >
                      <label class="custom-radio-input">
                        <input
                          type="radio"
                          :id="'size' + size.name + i + index"
                          :name="'size-select-' + index"
                          :value="'size' + size.name + i + index"
                          :checked="size.selected"
                        />
                        <span
                          class="custom-radio-input--btn font-weight-normal"
                          >{{ size.name }}</span
                        >
                      </label>
                    </li>
                  </ul>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </li>
      </ul>
    </div> -->
    <div class="d-flex quantity-and-btn" v-if="isLoggedIn == true">
      <div class="quantity-input d-flex">
        <b-link href="#" class="step-down-btn" @click="quantityDown"></b-link>
        <input
          type="number"
          min="1"
          :value="quantity"
          name="quantity"
          ref="inputQuantity"
        />
        <b-link href="#" class="step-up-btn" @click="quantityUp"></b-link>
      </div>
      <button
        v-on:click="addToCart"
        v-bind:disabled="btnDisable"
        class="btn btn-info product-parameter-submit-btn"
      >
        <i class="lnr lnr-cart"></i>{{ $t("add_to_cart") }}
      </button>
    </div>
    <div class="d-flex quantity-and-btn" v-if="isLoggedIn == false">
      {{ $t("only_available_when_logged_in") }}
    </div>
    <span
      class="d-block text-info delivery-info mb-20"
      v-if="product.DeliveryTime != null"
      :style="'color: ' + product.DeliveryTime.color + '!important'"
    >
      {{ product.DeliveryTime.long }}</span
    >
    <div class="product-detail--sku">{{ $t("sku") }}: {{ product.sku }}</div>
    <div
      class="d-flex flex-column flex-md-row justify-content-between more-info"
    >
      <div class="available-stores mb-20 mb-md-0">
        <span class="d-block mb-3">Beschikbaarheid:</span>
        <span
          v-if="product.stock_status == 'IN_STOCK'"
          class="d-block text-info"
          >{{ $t("in_stock") }}</span
        >
        <span v-if="product.stock_status != 'IN_STOCK'" class="d-block">{{
          $t("not_in_stock")
        }}</span>
      </div>
    </div>
    <div class="partners">
      <div v-if="typeof paymentPartners == 'object'">
        <ul class="payment-partners">
          <li v-for="(image, index) in paymentPartners.slides" :key="index">
            <img :src="image.media.url" :alt="image.title" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { mapGetters } from "vuex";
import { Logger } from "@storefront/core/lib/logger";
export default {
  name: "ProductParameters",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  props: {
    parameters: { type: Object },
    isInWishList: { type: Boolean },
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      options: "product/getCurrentProductOptions",
    }),
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },

    btnDisable() {
      if (this.isLoggedIn == true) {
        if (this.product.__typename == "ConfigurableProduct") {
          const retval = this.$store.getters["product/getCurrentChildSku"];
          if (retval != null) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    paymentPartners() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "payment_providers"
      );
    },
    finalPrice() {
      if (this.product.__typename == "ConfigurableProduct") {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.product.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.price_range.minimum_price.final_price.value.toFixed(
              2
            );
          }
        }
        if (
          this.product.price_range.minimum_price.final_price.value !=
          this.product.price_range.maximum.final_price.value
        ) {
          return (
            this.product.price_range.minimum_price.final_price.value.toFixed(
              2
            ) +
            " - " +
            this.product.price_range.maximum_price.final_price.value.toFixed(2)
          );
        }
        return this.product.price_range.minimum_price.final_price.value.toFixed(
          2
        );
      }
      if (
        this.product.price_range.minimum_price.final_price.value !=
        this.product.price_range.maximum.final_price.value
      ) {
        return (
          this.product.price_range.minimum_price.final_price.value.toFixed(2) +
          " - " +
          this.product.price_range.maximum_price.final_price.value.toFixed(2)
        );
      }
      return this.product.price_range.minimum_price.final_price.value.toFixed(
        2
      );
    },
    priceRange() {
      if (this.product.__typename == "ConfigurableProduct") {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.product.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.price_range;
          }
        }
        return this.product.price_range;
      }
      return this.product.price_range;
    },
    availability() {
      if (this.product.__typename == "SimpleProduct") {
        return this.product.store_availability;
      }
      if (this.product.__typename == "ConfigurableProduct") {
        Logger.debug(
          "availability",
          "ProductParameters",
          this.product.__typename
        )();
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.product.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.store_availability;
          }
        }
      }

      return [];
    },
  },

  data: () => ({
    quantity: 1,
    popup: false,
    priceSize: "",
    tableSize: [
      {
        name: "Billy / BHE",
        sizeS: "49.5 - 54 cm",
        sizeM: "55 - 58 cm",
        sizeL: "n/a",
      },
      {
        name: "Metro / BHE -55",
        sizeS: "n/a",
        sizeM: "55 - 58 cm",
        sizeL: "56 - 61 cm",
      },
      {
        name: "Indra / BHE -56",
        sizeS: "n/a",
        sizeM: "55 - 58 cm",
        sizeL: "58 - 62 cm",
      },
    ],
    fields: [
      {
        key: "name",
        label: "",
      },
      {
        key: "sizeS",
        label: "S",
      },
      {
        key: "sizeM",
        label: "M",
      },
      {
        key: "sizeL",
        label: "L",
      },
    ],
  }),
  methods: {
    clickOk() {
      this.$router.push("/checkout");
    },
    quantityUp() {
      this.quantity++;
    },
    quantityDown() {
      if (this.quantity > 1) this.quantity--;
    },
    processOption(index, value) {
      Logger.debug("processOption", "ProductParameters", {
        index: index,
        value: value,
      })();
      this.$store.commit("product/setOptionValue", {
        index: index,
        value: value,
      });
    },
    async addToCart() {
      switch (this.product.__typename) {
        case "SimpleProduct": {
          const item =
            '{data: {sku: "' +
            this.product.sku +
            '", quantity:' +
            this.quantity +
            "}}  ";
          let retval = await this.$store.dispatch("cart/addToCart", {
            type: this.product.__typename,
            item: item,
          });
          Logger.debug("retval", "addToCart", retval)();
          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("shopping_basket"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            this.$bvModal.show("modal-1");
          }
          break;
        }
        case "ConfigurableProduct": {
          const item =
            '{ parent_sku: "' +
            this.product.sku +
            '" data: {sku: "' +
            this.$store.getters["product/getCurrentChildSku"] +
            '", quantity:' +
            this.quantity +
            "}}  ";
          let retval = await this.$store.dispatch("cart/addToCart", {
            type: this.product.__typename,
            item: item,
          });
          Logger.debug("retval", "addToCart", retval)();
          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("add_to_cart"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            this.$bvModal.show("modal-1");
            const msg = {
              type: "success",
              title: this.$t("add_to_cart"),
              text: this.$t("added_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          }
          break;
        }

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";

.normal-price {
  padding-top: 24px;
  font-size: 16px;
  text-decoration: line-through;

  @include media("screen", ">=tablet") {
    padding-top: 0;
    margin-top: -8px;
  }
}

.my-4 {
  display: flex;
  margin-left: 12rem;
  margin-right: 5rem;
  margin-bottom: 1rem !important;
  margin-top: 2rem !important;
  align-items: center;
}

.my-price {
  display: flex;
  margin-left: 12rem;
  margin-right: 5rem;
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
  align-items: center;
  font-weight: bold;
}

img {
  width: 15%;
  border-style: none;
  margin-left: 18px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: inline;
  float: left;
}

.btn-primary {
  color: #fff;
  background-color: green !important;
}

.heading {
  padding-bottom: 6px;
  padding-top: 9px;

  a {
    text-decoration: underline;
    font-weight: 400;
    font-size: 16px;
  }
}

.multi-params {
  padding: 0 0 36px;
}

.card-row {
  width: 100%;

  .card {
    border: none;
    border-top: 1px solid #dfdfdf;
    width: 100%;
    padding: 10px 0 14px;
  }

  .card-body {
    letter-spacing: -0.02em;
    font-weight: 400;
    line-height: 1.2;

    @include media("screen", ">=tablet") {
      margin-right: -10px;
    }
  }

  .card-params {
    @include media("screen", ">=tablet") {
      padding-left: 15px;
    }
  }

  .color-selection,
  .size-selection-radio {
    width: 100%;
    padding: 0;
  }

  .color-selection {
    padding-bottom: 12px;

    .custom-radio-input--btn {
      min-width: 46px;
      padding: 1px 14px;
      margin-right: 5px;
    }
  }

  .size-selection-radio {
    padding-top: 12px;
    border-top: 1px solid #dfdfdf;
  }

  .selection-category {
    border: none;
    font-size: 14px;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-right: 16px;
    min-width: 70px;
  }

  .custom-radio-input--btn {
    min-width: 35px;
    padding: 1px 9px;
  }

  .card-body-img {
    margin-left: -8px;
    max-width: 63px;
    width: 100%;
  }

  ul {
    li:not(.card-row) {
      padding-top: 5px;
      padding-bottom: 5px;
      margin: 0 9px 0 0;
    }
  }

  label {
    margin-bottom: 0;
  }

  img {
    max-width: 100%;
    width: auto;
  }
}

.currentPrice {
  font-size: 20px;
  font-weight: 500;
}

.top-wrap {
  width: 100%;
  padding-right: 20px;

  .price-selection {
    margin-right: -20px;
  }

  .color-selection--available-colors {
    margin-right: -60px;
  }
}

.product-logo {
  max-width: 148px;
  margin-top: 20px;

  @include media("screen", ">=tablet") {
    margin-top: -10px;
  }

  img {
    width: 100%;
  }
}

.discount-comment {
  font-size: 16px;
  font-weight: normal;
  color: #ff8000;
  margin-left: 10px;
}

.selection-category {
  font-size: 16px;
  font-weight: normal;
  padding-bottom: 7px;
  display: block;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 19px;
}

.price-selection {
  margin-bottom: 14px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0 -7px;
  display: flex;
  flex-wrap: wrap;

  li:not(.card-row) {
    margin: 0 7px;
    flex-shrink: 0;
  }
}

.custom-radio-input {
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ .custom-radio-input--btn {
        background: #ff8000;
        color: white;
        border-color: #ff8000;
      }
    }
  }

  &--btn {
    display: block;
    border: 1px solid #000;
    padding: 1px 13px;
    font-size: 14px;
    cursor: pointer;
    min-width: 45px;
    text-align: center;

    &:hover {
      background: #ff8000;
      border-color: #ff8000;
      color: white;
    }
  }
}

.color-selection {
  padding-bottom: 16px;
}

.size-info {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: calc(100% - 2px);
    left: 0;
    right: 0;
    height: 1px;
    background: #000;
  }
}

.select-size {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    right: 0;
    height: 3px;
    background: #ff8000;
  }
}

select {
  width: 100%;
  border-radius: 0;
  border-color: #000;
  padding: 6px 35px 5px 7px;
  font-size: 14px;
}

.size-selection {
  padding-bottom: 17px;

  @include media("screen", ">=tablet") {
    padding-bottom: 23px;
  }
}

.size-selection-radio {
  padding-bottom: 18px;

  @include media("screen", ">=tablet") {
    padding-bottom: 27px;
  }
}

.quantity-input {
  width: 105px;
  flex-shrink: 0;
  border: 1px solid #000;

  input[type="number"] {
    border: none;
    width: 31px;
    padding: 3px 0;
    margin: 0;
    font-size: 17px;
    font-family: "Work Sans";
    text-align: center;
    font-weight: 600;
    outline: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .step-down-btn,
  .step-up-btn {
    display: block;
    position: relative;
    width: 37px;
    height: 100%;
    flex-shrink: 0;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 13px;
      height: 1px;
      background: #000;
    }
  }

  .step-up-btn {
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1px;
      height: 13px;
      background: #000;
    }
  }
}

.product-parameter-submit-btn {
  flex-grow: 1;
  margin-left: 14px;
  padding: 5px 10px;
  font-weight: normal;
  font-size: 17px;
  color: white;

  .lnr-cart {
    margin-right: 12px;
  }
}

.modal-title {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  border-radius: 16px;
  padding: 25px;
}

.quantity-and-btn {
  padding-bottom: 12px;
}

.more-info {
  line-height: 1.3;
}

.partners {
  width: 81%;
  margin: 12px auto;

  @include media("screen", ">=tablet") {
    width: auto;
    max-width: 420px;
    margin: 25px auto 16px;
  }

  img {
    width: 100%;
  }
}

.product-parameters {
  @include media("screen", ">=desktop") {
    min-height: 660px;
    margin-bottom: 55px;
  }

  &.overlay {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: -10px;
      bottom: 0;
      left: 0;
      right: 0;
      background: white;
      z-index: 9;
      opacity: 0.6;
    }
  }
}

.select-wrapper {
  position: relative;

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  select::-ms-expand {
    display: none;
  }
}
.product-detail--sku {
  margin-bottom: 6px;
  font-weight: normal;
  line-height: 1.3;
  @include media("screen", ">=tablet") {
    margin-bottom: 12px;
  }
}
</style>

<style lang="scss">
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
@import "@/esf_lommel_dt1_europe/scss/custom-bootstrap.scss";
@import "@/esf_lommel_dt1_europe/scss/main.scss";

.modal-open #modal-scrollable {
  .modal-header {
    padding: 10px 0 20px;
    border-bottom-color: $gray-900;

    .close {
      padding: 3rem;
      margin: -1rem -1rem -1rem auto;
    }
  }

  .modal-dialog {
    margin-top: 200px;
    max-width: 1044px;
    padding: 0 20px;
  }

  .modal-content {
    border: none;
    border-radius: 6px;
    padding: 0 12px;
    font-size: 12px;
    min-width: 320px;

    @include media("screen", ">=tablet") {
      padding: 0 28px;
      font-size: 21px;
    }
  }

  .modal-body {
    padding: 14px 0 44px 3px;
  }

  .size-table {
    th {
      padding-left: 23px;
      padding-right: 20px;

      @include media("screen", ">=tablet") {
        padding-left: 38px;
        padding-right: 38px;
      }
    }

    th,
    td {
      border: none;
    }
  }

  .text {
    font-size: 18px;

    @include media("screen", ">=tablet") {
      font-size: 21px;
    }
  }

  .close {
    position: relative;
    font-size: 0;
    line-height: 0;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 32px;
      right: 8px;
      width: 24px;
      height: 1px;
      background-color: $black;
      transform: rotate(45deg);

      @include media("screen", ">=tablet") {
        top: 38px;
      }
    }

    &:after {
      transform: rotate(-45deg);
    }

    &:focus {
      outline: none;
    }
  }
}

#modal-scrollable___BV_modal_header_ {
  .modal-title {
    font-size: 24px;

    @include media("screen", ">=tablet") {
      font-size: 31px;
    }
  }
}

#modal-scrollable___BV_modal_footer_ {
  display: none;
}

.modal-body {
  table {
    width: 100%;
  }

  thead {
    th {
      padding-bottom: 15px;
    }
  }

  tbody {
    font-weight: 400;

    th {
      font-weight: 400;
    }

    th,
    td {
      padding: 12px 0;
    }
  }
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
}

// de selecteer dropdownbox

.el-select {
  display: block;
  position: relative;

  .el-input {
    width: 100%;
    border-radius: 0;
    border-color: #000;
    font-size: 14px;
    position: relative;
    display: block;

    .el-input__inner {
      padding: 4px 35px 5px 26px;
      border-radius: 0;
      border-bottom-color: transparent;
      border-color: #000;
      height: 37px;
      line-height: 22px;
      cursor: pointer;
      background-color: #fff;
      background-image: none;
      border: 1px solid #dcdfe6;
      box-sizing: border-box;
      color: #606266;
      display: inline-block;
      font-size: inherit;
      transition: border-color 0.2s cubic-bezier(0.645 0.045, 0.355, 1);
      width: 100%;

      &::placeholder {
        color: #000;
        font-weight: normal;
      }
    }

    .el-input__prefix {
      left: 11px;
    }

    .el-input__suffix {
      top: 1px;
      right: 5px;
      transition: all 0.3s;
      position: absolute;
    }

    .el-select__caret.is-reverse {
      transform: rotateZ(180deg);
    }

    .el-select__caret {
      color: #c0c4cc;
      font-size: 14px;
      transition: transform 0.3s;
      transform: rotateZ(180deg);
      cursor: pointer;

      .el-input__icon {
        height: 100%;
        width: 25px;
        text-align: center;
        line-height: 40px;

        .el-icon-arrow-up.is-reverse {
          color: #000;
        }
      }
    }

    &.is-focus {
      .el-input__inner {
        border-bottom-color: transparent;
        border-color: #000;
      }
    }
  }
  .el-icon-arrow-up {
    &:before {
      content: "\e6e1";
      color: #000;
      font-size: 20px;
    }
    &:after {
      content: "";
      height: 100%;
      width: 0;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

[class*=" el-icon-"],
[class^="el-icon-"] {
  font-family: element-icons !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  vertical-align: baseline;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
}

.el-input__icon {
  height: 100%;
  width: 25px;
  text-align: center;
  line-height: 40px;
}

.el-popper[x-placement^="bottom"] {
  margin-top: -1px;
  border-radius: 0;
  border-color: #000;
  border-top: none;

  .popper__arrow {
    display: none;
  }
}

.el-select-dropdown {
  box-shadow: none;
  border: 1px solid #e4e7ed;
  background-color: #fff;
  box-sizing: border-box;
  margin: 5px 0;

  &__wrap {
    max-height: 330px;
  }

  &__list {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #dfdfdf;
    height: 31px;
    line-height: 29px;
    color: #000;
    background-color: white;
    border-color: rgb(201, 201, 201);
    font-size: 14px;
    padding: 0px 20px 0px 20px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    box-sizing: border-box;
    cursor: pointer;

    &.selected {
      background: #ff8000;
      color: #fff;
      font-weight: normal;
    }

    &.hover,
    &:hover {
      @include media("screen", ">=desktop") {
        background: #ff8000;
        color: #fff;
        font-weight: normal;
      }
    }
  }
}
</style>

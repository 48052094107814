<template>
  <div class="alert-block">
    <b-alert 
      v-model="showDismissibleAlert"
      class="d-flex align-items-center"
      :variant="product.alertVariant || 'success'" 
      dismissible>
      <div v-if="isInfoAlert()" class="alert-content">
        <b-icon-bell></b-icon-bell>
        <span>Let op! probeer het nog eens</span>
      </div>
      <div v-if="isDangerAlert()" class="alert-content">
        <i class="lnr lnr-cross-circle"></i>
        <span>Mislukt, probeer het nog eens</span>
      </div>
      <div v-if="isWarningAlert()" class="alert-content">
        <i class="lnr lnr-warning"></i>
        <span>Waarschuwing</span>
      </div>
      <div
        v-if="isSuccessAlert() || (!isWarningAlert() && !isInfoAlert() && !isDangerAlert())"
        class="d-flex justify-content-between align-items-center alert-content"
      >
        <div class="left-content">
          <i class="lnr lnr-checkmark-circle"></i>
          <span>{{ product.name }}</span>
          <span> aan je</span>
          <b-link href="#" class="content-link"> verlanglijstje.</b-link>
        </div>
        <b-link href="#" class="btn alert-btn ml-20">Wensenlijst</b-link>
      </div>
    </b-alert>
  </div>
</template>

<script>


export default {
  name: 'ProductAlert',
  props: ['product', 'showDismissibleAlert'],
  components: {
    
  },
  data() {
    return {
      
    }
  },
  methods: {
    isInfoAlert() {
      return this.product.alertVariant === 'info'
    },
    isDangerAlert() {
      return this.product.alertVariant === 'danger'
    },
    isWarningAlert() {
      return this.product.alertVariant === 'warning'
    },
    isSuccessAlert() {
      return this.product.alertVariant === 'success'
    },
  },
}
</script>

<style lang="scss" scoped>
  $alert-bg: (
    info: #f9f8c8,
    danger: #fbc8c7,
    warning: #fce3c4,
    success: #eaf8e7
  );

  .alert-block {
    @each $bg, $color in $alert-bg {
      .alert-#{$bg} {
        background-color: $color;
      }
    }

    .alert {
      border: none;
      min-height: 60px;
      padding-right: 42px !important;
    }

    .alert-content {
      color: #000;
      font-weight: 400;
      width: 100%;

      i {
        font-size: 20px;
        margin-right: 9px;
        display: inline-block;
        vertical-align: text-top;
      }
      
      svg {
        margin-right: 14px;
        height: 18px;
        width: 18px;
      }

      .content-link {
        position: relative;
        margin-left: 2px;
        &:after {
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          height: 1px;
          background: #000;
        }
      }
    }

    .alert-btn {
      font-size: 17px;
      padding: 3px 18px;
      border: 1px solid #000 !important;
      font-weight: 300;

      &:hover {
        color: #000 !important;
        background: lighten(#ff8000, 30%);
      }
    }

    button.close {
      font-size: 0;
      top: 0;
      bottom: 0;
      right: 0;
      width: 45px;
      outline: none !important;
      opacity: 1;
      text-shadow: none;

      &:hover {
        opacity: 0.6;
      }

      &:after {
        content: '\e870';
        font-family: 'Linearicons-Free';
        font-size: 18px;
        color: #000;
      }
    }
  }
</style>

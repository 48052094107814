<template>
  <BaseLayout>
    <Breadcrumbs />
    <section class="product product-section">
      <div class="container">
        <ProductAlert
          :showDismissibleAlert="showDismissibleAlert"
          :product="product"
        />
        <span class="product--name d-block">{{ product.name }}</span>
        <div class="row pb-md-40">
          <div class="col-12 col-md-6">
            <ProductGallery
              v-if="product.media_gallery.length > 0"
              @changeIsInWishList="UpdateIsInWishList"
              :images="product.media_gallery"
              :isInWishList="
                $store.getters['user/isProductInWishlist'](product.sku)
              "
            />
            <ProductGallery
              v-if="product.media_gallery.length == 0"
              @changeIsInWishList="UpdateIsInWishList"
              :images="[product.image]"
              :isInWishList="
                $store.getters['user/isProductInWishlist'](product.sku)
              "
            />
            <DescriptionTab class="d-none d-md-block" />
          </div>
          <div class="col-12 col-md-6">
            <ProductParameters :isInWishList="false" />
          </div>
        </div>
        <div class="row pb-md-70 d-md-none">
          <DescriptionTab class="col-12 col-md-6" />
        </div>
        <div
          v-if="product.related_products.length > 0"
          class="product-gallery-block"
        >
          <h2>{{ $t("related_products") }}</h2>
          <MoreProductsCarousel :products="product.related_products" />
        </div>
      </div>
    </section>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/esf_lommel_dt1_europe/core/components//BaseLayout";
import { mapGetters } from "vuex";

import Breadcrumbs from "@/esf_lommel_dt1_europe/core/components//Breadcrumbs";
import ProductAlert from "@/esf_lommel_dt1_europe/core/components//ProductAlert";
import ProductGallery from "@/esf_lommel_dt1_europe/core/components//ProductGallery";
import ProductParameters from "@/esf_lommel_dt1_europe/core/components//ProductParameters";
import DescriptionTab from "@/esf_lommel_dt1_europe/core/components//DescriptionTab";
import MoreProductsCarousel from "@/esf_lommel_dt1_europe/core/components//MoreProductsCarousel";
//import wishListAdapter from "@/esf_lommel_dt1_europe/helpers/adapters/wishListAdapter";
export default {
  name: "ProductPage",
  components: {
    BaseLayout,
    Breadcrumbs,
    ProductGallery,
    ProductParameters,
    DescriptionTab,
    MoreProductsCarousel,
    ProductAlert,
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
    }),
    showDismissibleAlert() {
      return false;
    },
  },
  methods: {
    UpdateIsInWishList() {
      if (
        this.$store.getters["user/isProductInWishlist"](this.product.sku) ==
        true
      ) {
        this.$store.dispatch("user/setProductWishlistStatus", {
          sku: this.product.sku,
          parentSku: null,
        });
      } else {
        if (this.product.__typename == "SimpleProduct") {
          this.$store.dispatch("user/setProductWishlistStatus", {
            sku: this.product.sku,
            parentSku: null,
          });
        } else {
          const retval = this.$store.getters["product/getCurrentChildSku"];
          if (retval == null) {
            const msg = {
              type: "danger",
              title: this.$t("wishlist error"),
              text: this.$t("wishlist_select_options"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
            //this.$router.push("/" + this.product.url_key);
          } else {
            this.$store.dispatch("user/setProductWishlistStatus", {
              sku: this.$store.getters["product/getCurrentChildSku"],
              parentSku: this.product.sku,
            });
          }
        }
      }
    },
  },
  data() {
    return {};
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.product.meta_title },
        { name: "keywords", content: this.product.meta_keyword },
        { name: "description", content: this.product.meta_description },
        { property: "og:type", content: "product" },
        { property: "og:title", content: this.product.meta_title },
        { property: "og:image", content: this.product.image.url },
        { property: "og:description", content: this.product.meta_description },
        {
          property: "product:price:amount",
          content: this.product.price_range.minimum_price.final_price.value,
        },
        {
          property: "product:price:currency",
          content: "EUR",
        },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.product.name,
    };
  },
};
</script>

<style lang="scss">
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";

.product-section {
  &.product {
    @include media("screen", ">=tablet") {
      padding-top: 8px;
    }
  }

  .product--name {
    font-size: 20px;
    font-weight: normal;
    display: block;
    margin-bottom: 23px;
  }
}

.product-gallery-block {
  h2 {
    margin-bottom: 5px;
    font-weight: 700;
    color: #0c1f47;
    font-size: 20px;
    letter-spacing: 0.01em;
  }
}
</style>

<template>
  <div class="ratings d-none d-md-flex align-items-center">
    <div class="price">{{ title }}</div>
    <ul class="ratings-star">
      <li
        v-for="(isFill, idx) of rateStars"
        :key="idx"
        :class="isFill ? 'text-yellow' : 'text-gray'"
      >
        <i class="lnr lnr-star"></i>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ProductRating",
  props: {
    stars: {
      type: String,
    },
    title: {
      type: String,
    },
  },
  computed: {
    rateStars() {
      return new Array(5).fill(false).map((_, i) => i < this.stars);
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.ratings {
  padding: 5px 0;
  line-height: 1;
  margin-bottom: 7px;
}

.text-yellow {
  color: green;
}

.text-gray {
  color: #cdcdcd;
}

.ratings-star {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0 -4px;
  padding-left: 10px;

  li {
    margin: 0 2px;
  }
}

.no-of-ratings {
  font-family: "Work Sans";
  font-weight: 300;
  font-size: 15px;
  margin-left: 9px;
}
</style>

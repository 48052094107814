<template>
  <div class="ProductReview">
    <b-modal
      id="modal-review"
      okVariant="success"
      okTitle="Ok"
      :title="$t('review_send')"
      @ok="clickReviewOk"
    >
      <p class="my-4">{{ $t("review_thankyou") }}</p>
    </b-modal>

    <h3>{{ $t("productreview") }}</h3>
    <div class="review-block">
      <div class="row">
        <div class="col-xs-12">
          <div v-if="product != null">
            <div v-if="product.reviews.items.length == 0">
              {{ $t("no_reviews") }}
            </div>
            <div v-for="(rev, index) of product.reviews.items" :key="index">
              <div v-for="(rat, idx) of rev.ratings_breakdown" :key="idx">
                <ProductRating
                  :title="rat.name"
                  :stars="rat.value"
                ></ProductRating>
              </div>
              <div class="nickname">{{ rev.nickname }}</div>
              <div class="summary">{{ rev.summary }}</div>
              <div class="review">{{ rev.text }}</div>
            </div>
          </div>
          <div v-if="isLoggedIn == true">
            <h4>{{ $t("you'rereviewing") }}</h4>
            <div v-for="(ritem, idx) of reviewMeta" :key="idx">
              <ReviewRating
                :reviewData="ritem"
                v-model="ratings[idx]"
              ></ReviewRating>
            </div>
            <div id="Nickname" role="group" class="form-group review-inputs">
              <label id="first-Nickname" for="first_name" class="d-block">{{
                $t("nickname")
              }}</label>
              <div class="no-focus-ring">
                <input
                  id="first_name"
                  type="text"
                  class="form-control"
                  aria-describedby="firstnickname-Review"
                  v-model="nickname"
                  required="true"
                />
                <div id="firstnickname-review" class="invalid-nickname">
                  {{ this.nickname_error }}
                </div>
              </div>
            </div>
            <div id="Summary" role="group" class="form-group review-inputs">
              <label id="Summary" for="Summary" class="d-block">{{
                $t("summary")
              }}</label>
              <div class="no-focus-ring">
                <input
                  id="Summary"
                  type="text"
                  class="form-control"
                  aria-describedby="Summary-Review"
                  v-model="summary"
                  required="true"
                />
                <div id="Summary-review" class="invalid-Summary">
                  {{ this.summary_error }}
                </div>
              </div>
            </div>
            <div id="Review" role="group" class="form-group review-inputs">
              <label id="Review" for="Review" class="d-block">{{
                $t("review")
              }}</label>
              <div class="no-focus-ring">
                <textarea
                  name="Review"
                  cols="40"
                  rows="5"
                  v-model="text"
                  required="true"
                ></textarea>
                <div id="review" class="invalid-Review">
                  {{ this.text_error }}
                </div>
              </div>
            </div>
            <button @click="sendReview" class="submit reviewbutton">
              {{ $t("submitreview") }}
            </button>
          </div>
          <div v-if="isLoggedIn != true">
            <p v-text="$t('You need to be logged in to place a review')"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProductRating from "@/esf_lommel_dt1_europe/core/components//ProductRating";
import ReviewRating from "@/esf_lommel_dt1_europe/core/components//ReviewRating";
import { createProductReview } from "@storefront/core/data-resolver/products";
import { Logger } from "@storefront/core/lib/logger";
export default {
  name: "ProductReview",
  components: {
    ProductRating,
    ReviewRating,
  },
  beforeCreate() {},
  data() {
    return {
      test: null,
      nickname: "",
      summary: "",
      text: "",
      ratings: [],
      nickname_error: "",
      summary_error: "",
      text_error: "",
    };
  },
  methods: {
    async sendReview() {
      const ratings = [];
      let formValid = true;
      if (this.nickname == "") {
        formValid = false;
        this.nickname_error = "This is a required field";
      }
      if (this.summary == "") {
        formValid = false;
        this.summary_error = "This is a required field";
      }
      if (this.text == "") {
        formValid = false;
        this.text_error = "This is a required field";
      }
      Logger.debug("sendReview", "ProductReview", formValid)();
      Logger.debug("errorCheck", "ProductReview", [
        this.nickname_error,
        this.summary_error,
        this.text_error,
      ])();
      if (formValid) {
        this.reviewMeta.forEach((element, index) => {
          if (typeof this.ratings[index] != "undefined") {
            let obj = { id: element.id, value_id: this.ratings[index] };
            ratings.push(obj);
          } else {
            //alert("AAAHHHHH");
          }
        });
        const inp = {};
        inp.sku = this.product.sku;
        inp.nickname = this.nickname;
        inp.summary = this.summary;
        inp.text = this.text;
        inp.ratings = ratings;
        this.test = inp;
        Logger.debug("inp", "ProductReview", inp)();
        const retval = await createProductReview(inp);
        Logger.debug("retval", "ProductReview", retval)();
        if (retval != false) {
          this.$bvModal.show("modal-review");
          this.nickname = "";
          this.summary = "";
          this.text = "";
          this.ratings.forEach((element, index) => {
            element = null;
            this.ratings[index] = null;
          });
        }
      }
    },
    clickReviewOk() {
      this.$bvModal.hide("modal-review");
    },
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      reviewMeta: "product/getProductReviewRatingsMetadata",
    }),
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
};
</script>
<style>
button {
  background-color: #00893d;
  border: none;
  color: white;
  padding: 6px 26px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.reviewbutton:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.nickname {
  padding-bottom: 4px;
}

.summary {
  padding-bottom: 4px;
}

.price {
  font-weight: 600;
  color: #000;
}

.ratings {
  margin-top: 20px;

  padding-top: 18px;
}

.review {
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 20px;
  padding-bottom: 18px;
}

h3 {
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 15px;
}

/* h4 {
  margin-bottom: 26px !important;
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
  margin-top: 26px;
  padding-top: 18px;
  padding-bottom: 18px;
} */

.review-block {
  padding-bottom: 28px;
}

.review-inputs {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
  margin-top: 30px;
  font-size: 14px;
}

@media screen and (min-width: 768px) {
  .review-inputs {
    padding-right: 14px;
    font-size: 16px;
    display: block;
  }
}

.review-inputs label {
  min-width: 110px;
  width: auto;
  font-weight: 400;
  padding-right: 2px;
}

.review-inputs > div {
  width: 100%;
}

.review-inputs input {
  border-color: #7f7f7f;
  border-radius: 6px;
}

.invalid-nickname,
.invalid-Summary,
.invalid-Review {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e30613;
}

.review-inputs textarea {
  border-color: #7f7f7f;
  border-radius: 6px;
}
textarea {
  display: block;
  width: 100%;
  height: calc(1.6em + 1.6rem + 2px);
  padding: 0.8rem 1.3rem;
  font-size: 1.5rem;
  font-weight: 300;
}

/* .row {
  margin-bottom: 26px;
  border-bottom: 1px solid #000;
  padding-bottom: 18px;
} */
</style>

<template>
  <div class="product-card--holder">
    <b-link v-if="isLoggedIn" class="add-to-wishlist" @click="toggleWishItem">
      <div
        class="heart"
        v-if="$store.getters['user/isProductInWishlist'](product.sku)"
      >
        <img src="@/esf_lommel_dt1_europe/assets/heart.png" alt="heart" />
      </div>
      <i class="lnr lnr-heart" v-else></i>
    </b-link>
    <b-link :to="$link(`/${product.url_key}`)" class="product-card">
      <div
        v-if="
          product.thumbnail.medium != null &&
          !product.thumbnail.medium.match(/\/.jpg/gi)
        "
      >
        <div
          class="product-card--img-top"
          :style="`background-image: url('` + product.thumbnail.medium + `');`"
        >
          <div class="actionbuttons">
            <span class="sale-txt" v-if="onSale == true">sale</span>
            <span class="sale-txt" v-if="onNew == true">new</span>
          </div>
        </div>
      </div>
      <div
        v-if="
          product.thumbnail.medium == null ||
          product.thumbnail.medium.match(/\/.jpg/gi)
        "
      >
        <div
          class="product-card--img-top"
          :style="`background-image: url('` + product.thumbnail.url + `');`"
        >
          <div class="actionbuttons">
            <span class="sale-txt" v-if="onSale == true">sale</span>
            <span class="sale-txt" v-if="onNew == true">new</span>
          </div>
        </div>
      </div>

      <div class="product-card--product-name">{{ product.name }}</div>
      <div class="product-card--sku">{{ $t("sku") }}: {{ product.sku }}</div>
      <ul
        v-for="attr of product.configurable_options"
        :key="attr.id"
        class="available-sizes-list d-none d-md-flex flex-wrap justify-content-center list-unstyled p-0"
      >
        <li v-for="opt of attr.values" :key="opt.value_index">
          {{ opt.label }}
        </li>
      </ul>
      <Rating
        :stars="product.rating_summary"
        :noOfRatings="product.review_count"
      />
      <div v-if="isLoggedIn == true">
        <span
          v-if="
            product.price_range.minimum_price.final_price.value ==
            product.price_range.maximum_price.final_price.value
          "
          class="d-block product-card--price"
          >&euro;{{
            product.price_range.minimum_price.final_price.value.toFixed(2)
          }}</span
        >
        <span
          v-if="
            product.price_range.minimum_price.final_price.value !=
            product.price_range.maximum_price.final_price.value
          "
          class="d-block product-card--price"
          >&euro;{{
            product.price_range.minimum_price.final_price.value.toFixed(2)
          }}
          - &euro;{{
            product.price_range.maximum_price.final_price.value.toFixed(2)
          }}</span
        >
        <span
          class="d-block product-card--sale-comment"
          v-if="product.sale == 1"
          >{{ $t("discount") }}
          {{
            product.price_range.minimum_price.discount.percent_off.toFixed(2)
          }}%</span
        >
      </div>
    </b-link>
  </div>
</template>

<script>
import Rating from "@/esf_lommel_dt1_europe/core/components//Rating";
//import { Logger } from "@storefront/core/lib/logger";
// import VueLazyBackgroundImage from "@/esf_lommel_dt1_europe/core/components//core/VueLazyBackgroundImage";
import logoImage from "@/esf_lommel_dt1_europe/assets/logo.png";

export default {
  name: "ProductCard",
  props: {
    product: {
      type: Object,
      require: true,
    },
  },
  components: {
    Rating,
    // VueLazyBackgroundImage,
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },

    onSale() {
      const today = new Date();
      if (this.product.special_from_date != null) {
        if (new Date(this.product.special_from_date) <= today) {
          if (new Date(this.product.special_to_date) >= today) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    onNew() {
      const today = new Date();
      if (this.product.new_from_date != null) {
        if (new Date(this.product.new_from_date) <= today) {
          if (this.product.new_to_date != null) {
            if (new Date(this.product.new_to_date) >= today) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  methods: {
    toggleWishItem() {
      if (
        this.$store.getters["user/isProductInWishlist"](this.product.sku) ==
        true
      ) {
        this.$store.dispatch("user/setProductWishlistStatus", {
          sku: this.product.sku,
          parentSku: null,
        });
      } else {
        if (this.product.__typename == "SimpleProduct") {
          this.$store.dispatch("user/setProductWishlistStatus", {
            sku: this.product.sku,
            parentSku: null,
          });
        } else {
          const msg = {
            type: "danger",
            title: this.$t("wishlist error"),
            text: this.$t("wishlist_select_options"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.$router.push("/" + this.product.url_key);
        }
      }
    },
  },
  data: () => ({
    logoImg: logoImage,
  }),
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
.product-card--img-top {
  background-size: contain;
}
.add-to-wishlist {
  position: absolute;
  right: 25px;
  top: 3px;
  font-size: 21px;
  z-index: 9;
  width: 22px;
  height: 33px;

  @include media("screen", ">=tablet") {
    right: 30px;
    top: 9px;
  }

  &:hover {
    ~ .product-card {
      color: #000 !important;
      box-shadow: 0 0 5px 1px rgba(0, 0, 0, 20%);
    }
  }

  .heart {
    position: absolute;
    top: -2px;
    right: 0;
  }
}

.product-card {
  padding: 10px 18px;
  display: block;
  height: 100%;

  @include media("screen", ">=tablet") {
    padding: 10px 17px;
  }

  &:hover {
    color: #000 !important;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 20%);
  }

  &--holder {
    display: block;
    width: 100%;
    height: 100%;
  }

  &--img-top {
    width: 100%;
    height: 185px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin-bottom: 61px;
    position: relative;

    @include media("screen", ">=400px") {
      background-size: auto 100%;
    }

    @include media("screen", ">=tablet") {
      margin-bottom: 65px;
    }
  }

  &--product-name {
    display: block;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.3;
    height: 60px;
    margin-bottom: 6px;
    overflow: hidden;

    @include media("screen", ">=tablet") {
      height: 40px;
      margin-bottom: 12px;
    }
  }

  &--sku {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.3;
    margin-bottom: 6px;
    @include media("screen", ">=tablet") {
      margin-bottom: 12px;
    }
  }

  &--price {
    font-size: 16px;
    font-weight: 500;
  }

  &--sale-comment {
    font-size: 15px;
    font-weight: normal;
    color: #ff8000;
  }

  .actionbuttons {
    position: absolute;
    top: calc(100% - 28px);
    left: 3px;

    @include media("screen", ">=tablet") {
      top: calc(100% + 30px);
      left: 0;
    }
    .sale-txt {
      background: #ff8000;
      color: white;
      font-size: 14px;
      padding: 1px 12px;
    }
  }
}

.available-sizes-list {
  margin: 0 -0.7rem;
  @include media("screen", ">=widescreen") {
    margin: 0 -0.7rem 1.1rem -0.3rem;
  }
  li {
    background: #008a3e;
    color: white;
    min-width: 45px;
    height: 25px;
    margin: 0.45rem 0.7rem;
    text-align: center;
    padding: 4px 5px;
    line-height: 1;
  }
}
</style>

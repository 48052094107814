<template>
  <ClientOnly>
    <Carousel
      class="more-products--carousel"
      :autoplay="false"
      :loop="true"
      :navigationEnabled="true"
      :perPage="slidePerPage"
      :scrollPerPage="false"
      :paginationEnabled="false"
      navigationPrevLabel="<i class='lnr lnr-chevron-left'></i>"
      navigationNextLabel="<i class='lnr lnr-chevron-right'></i>"
    >
      <Slide
        v-for="product of products"
        :key="product.sku"
        class="more-products--slide position-relative"
      >
        <ProductCard :product="product" />
      </Slide>
    </Carousel>
  </ClientOnly>
</template>

<script>
//import { Carousel, Slide } from 'vue-carousel'
import { isServer } from "@storefront/core/helpers";
import ClientOnly from "vue-client-only";

import { mapActions } from "vuex";
import ProductCard from "@/esf_lommel_dt1_europe/core/components//ProductCard";

export default {
  name: "MoreProductsCarousel",
  components: {
    ClientOnly,
    Carousel: () =>
      !isServer ? import("vue-carousel").then((m) => m.Carousel) : null,
    Slide: () =>
      !isServer ? import("vue-carousel").then((m) => m.Slide) : null,
    ProductCard,
  },
  props: {
    products: { type: Array },
  },
  mounted() {
    if (!isServer) {
      window.addEventListener("resize", this.setNoOfSlidePerPage);
      window.addEventListener("load", this.setNoOfSlidePerPage);
      this.setNoOfSlidePerPage();
    }
  },
  destroyed() {
    if (!isServer) {
      window.removeEventListener("resize", this.setNoOfSlidePerPage);
      window.removeEventListener("load", this.setNoOfSlidePerPage);
    }
  },
  data() {
    return {
      slidePerPage: this.setNoOfSlidePerPage(),
    };
  },
  methods: {
    ...mapActions(["setProductStatus"]),
    setNoOfSlidePerPage() {
      if (window.innerWidth < 768) {
        this.slidePerPage = 2;
      } else if (window.innerWidth > 767 && window.innerWidth < 992) {
        this.slidePerPage = 3;
      } else {
        this.slidePerPage = 5;
      }
    },
    toggleWishListStatus(productId) {
      this.setProductStatus({ productId });
    },
  },
};
</script>

<style lang="scss">
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
.more-products--carousel {
  padding-top: 34px;
  padding-bottom: 12px;

  @include media("screen", ">=tablet") {
    padding-top: 15px;
    padding-bottom: 20px;
  }

  .add-to-wishlist {
    width: 22px;
    height: 33px;
    position: absolute;
    top: 7px;
    right: 24px;
    font-size: 20px;
    z-index: 8;

    .heart-icon {
      position: absolute;
      top: -3px;
      right: 1px;
      z-index: 8;
    }

    &:hover {
      ~ .product-card {
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, 20%);
      }
    }
  }

  .product-img {
    height: 180px;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    margin-bottom: 57px;
    position: relative;

    .sale {
      position: absolute;
      bottom: -43px;
      left: 0;
      font-family: "Work Sans";
      font-size: 13px;
      background: #ff8000;
      color: white;
      padding: 2px 8px;
    }
  }

  .VueCarousel-inner {
    margin: 0 -20px;
  }

  .VueCarousel-wrapper {
    padding: 5px;
  }

  .VueCarousel-navigation-button {
    outline: none !important;
    font-size: 18px;
    top: calc(50% - 26px);
  }

  .VueCarousel-navigation-prev {
    left: 22px !important;

    @include media("screen", ">=tablet") {
      left: 0 !important;
    }
  }

  .VueCarousel-navigation-next {
    right: 22px !important;

    @include media("screen", ">=tablet") {
      right: 0 !important;
    }
  }

  .product-card {
    display: block;
    padding: 27px 15px;
    margin: 0 20px;
    height: 100%;

    &:hover {
      box-shadow: 0 0 5px 1px rgba(0, 0, 0, 20%);
      color: #000 !important;
    }
  }

  .product-name {
    display: block;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.3;
    margin-bottom: 11px;
  }

  .product-price {
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
  }

  .sale-comment {
    color: #ff8000;
  }
}
</style>

<template>
  <b-tabs
    active-nav-item-class="active-opener"
    class="product--description-tab"
  >
  <b-tab :title="`${$t('product_description')}`" active>
      <!-- <span class="tab-inner-title mb-9 font-weight-normal d-block">{{
        $t("details")
      }}</span> -->
        <table v-if="partfinderCheck()"  class="partfinder-table" style="width:100%">
          <tr class="partfinder-row">
            <th class="partfinder-column type" style="text-align: center; padding-left: 5px; padding-right: 5px;">
              {{$t("type_desc")}}
            </th>
            <th class="partfinder-column" style="text-align: center; padding-left: 5px; padding-right: 5px;">
              {{$t("brand_desc")}}
            </th>
            <th class="partfinder-column" style="text-align: center; padding-left: 5px; padding-right: 5px;">
              {{$t("model_desc")}}
            </th>
            <th class="partfinder-column" style="text-align: center; padding-left: 5px; padding-right: 5px;">
              {{$t("cilinder_desc")}}
            </th>
            <th class="partfinder-column" style="text-align: center; padding-left: 5px; padding-right: 5px;">
              {{$t("year_desc")}}
            </th >
          </tr>
          <tr class="partfinder-value-row" v-for="(part, index) in groupedData" :key="index">
            <td  class="partfinder-column type" style="text-align: center; padding-left: 5px; padding-right: 5px;">
              <span class="partfinder-value">{{ part.type }}</span>
            </td>
            <td class="partfinder-column" style="text-align: center; padding-left: 5px; padding-right: 5px;">
              <span class="partfinder-value">{{ part.brand }}</span>
            </td>
            <td class="partfinder-column" style="text-align: center; padding-left: 5px; padding-right: 5px;">
              <span class="partfinder-value">{{ part.model }}</span>
            </td>
            <td class="partfinder-column" style="text-align: center; padding-left: 5px; padding-right: 5px;">
              <span class="partfinder-value">{{ part.cilinder }}</span>
            </td>
            <td class="partfinder-column" style="text-align: center; padding-left: 5px; padding-right: 5px;">
              <span class="partfinder-value">{{getYearDifference(part.years, $t("to"))}}</span>
            </td>
          </tr>
        </table>
      <div v-else v-html="product.short_description.html"></div>
    </b-tab>
    <b-tab :title="`${$t('product_review')} (${product.review_count})`">
      <ProductReview></ProductReview>
    </b-tab>
    <b-tab :title="$t('product_specifications')">
      <div
        class="d-flex mb-2"
        v-for="(info, index) in product.information_attributes"
        :key="index"
      >
        <span class="category d-block">{{ info.label }}</span>
        <span class="detail ml-20 d-block">{{ info.value }}</span>
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import { mapGetters } from "vuex";
//import VRuntimeTemplate from "v-runtime-template";
import ProductReview from "@/esf_lommel_dt1_europe/core/components//ProductReview";
export default {
  name: "DescriptionTab",
  components: {
    //VRuntimeTemplate
    ProductReview,
  },
  computed: {
    groupedData() {
      const groupedData = {};
      if(this.product.partfinder[0].brand != null, this.product.partfinder[0].cilinder != null, this.product.partfinder[0].model != null, this.product.partfinder[0].type != null, this.product.partfinder[0].year != null){
        this.product.partfinder.forEach(item => {
          const id = `${item.brand.name || ''}, ${item.cilinder.name || ''}, ${item.model.name || ''}`;
          if (!groupedData[id]) {
            groupedData[id] = {
              
              brand: item.brand.name.charAt(0) + item.brand.name.substring(1).toLowerCase(),
              type: item.type.name.charAt(0) + item.type.name.substring(1).toLowerCase(),
              cilinder: item.cilinder.name,
              model: item.model.name,
              years: []
            };
          }
          if (!groupedData[id].years.includes(item.year.name)) {
            groupedData[id].years.push(item.year.name);
          }
        });
        return Object.values(groupedData);
      } else {
        return [];
      }
    },
    ...mapGetters({
      product: "product/getCurrentProduct",
    }),
  },
  methods: {
    partfinderCheck() {
      try {
          if (this.product.partfinder.length > 0) {
          return true;
        }
      } catch (error) {
        return false;
      }

    },
    getYearDifference(years, splittingCharacter) {
      switch (years.length) {
        case 0:
          return "";
        case 1: 
          return years[0];
        case 2: 
          return years[0] + " & " + years[1];
        default:
          return years[0] + " " + splittingCharacter + " " + years[years.length - 1];
      }
    },
  },
  data: () => ({
    description: {
      details:
        "De Trani is een winterjack van Rogelli die speciaal is ontworpen voor de winter. Inmiddels al aan de sterk verbeterde versie 3.0. Het jack is winddicht en waterafstotend zodat je optimaal kunt presteren in koude weersomstandigheden. Voor optimaal comfort zijn er softshell stukken aan de voorkant en isolerend fleece aan de binnenvoering aangebracht. Aan de achterzijde zijn 3 zakjes.",
      features: [
        "Winddicht en waterafstotend",
        " Race pasvorm",
        " Warmte isolerend fleece binnenvoering",
        " Elastische band aan de pols",
        " Softshell stukken op de voorkant",
        " Silicone antislipstrip in de taille",
        " Extra rug- en borstzak met ritssluiting",
        "D.T. PENSTAR stof",
        "Lange rits",
        "Reflecterende accenten voor extra zichtbaarheid",
      ],
      moreInfo: [
        { category: "Productgroep", info: "Fietsjack winter" },
        { category: "Introductie jaar", info: "2018" },
        { category: "Gebruikte materialen", info: "100% polyester" },
        {
          category: "Temperatuuradvies",
          info: "Vanaf temperaturen onder het vriespunt",
        },
      ],
    },
    reviews: {
      noOfReviews: 15,
    },
  }),
};
</script>

<style lang="scss">
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";

.partfinder-row .type {
  display: block;
  visibility: visible;
  @include media("screen", "<=desktop") {
    display: none;
    visibility: hidden;
  }
}
.partfinder-value-row .type {
  display: block;
  visibility: visible;
  @include media("screen", "<=desktop") {
    display: none;
    visibility: hidden;
  }
}
.partfinder-value {
  font-size: 12px;
  @include media("screen", ">=s-phone") {
    font-size: 14px;

  }
  @include media("screen", ">=phone") {
    font-size: 15px;
  }
}


.product--description-tab {
  .nav-tabs {
    padding-bottom: 2px;
    .nav-item {
      margin-bottom: 0;

      + .nav-item {
        margin-left: 20px;

        @include media("screen", ">=tablet") {
          margin-left: 29px;
        }

        @include media("screen", ">=desktop") {
          margin-left: 59px;
        }
      }
    }

    .nav-link {
      border: none !important;
      font-size: 14px;
      font-weight: normal;
      color: #000 !important;
      position: relative;
      padding: 5px 0;

      @include media("screen", ">=tablet") {
        font-size: 16px;
      }

      &.active-opener {
        &:before {
          content: "";
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          height: 3px;
          background: #ff8000;
        }
      }
    }
  }

  .tab-pane {
    padding-top: 15px;
  }

  .features {
    list-style: none;
    padding-left: 0;
    padding-bottom: 23px;
    margin: 0;

    li {
      padding-left: 24px;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 10px;
        left: 7px;
        width: 4px;
        height: 4px;
        background: #000;
        border-radius: 100%;
      }
    }
  }

  p {
    margin-bottom: 18px;
  }

  .category {
    width: 34.5%;
    font-size: 14px;
  }

  .detail {
    font-size: 14px;
  }
}
</style>
